import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router ,RouterLink} from '@angular/router';
import {trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from 'src/app/services/language/language.service';
import { ThisReceiver } from '@angular/compiler';
import { TemplateManagerService } from 'src/app/services/fire/template.service';

import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations:[
    trigger("animateMenu",[
      transition(":enter",[
        query("*", [
          style({opacity: 0, transform: "translateY(-50%)"}),
          stagger(50,[
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity: 1, transform: "none"}))
          ])
        ])
      ])
    ])
  ]
})



export class HeaderComponent implements OnInit {
downloadPriceList() {
throw new Error('Method not implemented.');
}

  responsiveMenuVisible: Boolean = false;
  pageYPosition: number;
  languageFormControl: FormControl= new FormControl();
  cvName: string = "";
  showPasswordInput = false;
  password = '';
  correctPassword:string;
  adminSuccess: boolean = false;
  dataCollection: any[];



  constructor(
    private router: Router,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService,
    private templateManagerService: TemplateManagerService,
    private route : Router
  ) { 

  }

  ngOnInit(): void {
    
    this.templateManagerService.getDataCollection().subscribe(data => {
      this.correctPassword = data[0].adminPwd;
      localStorage.setItem('adminPwd',this.correctPassword)
    });
  }

  
  togglePasswordInput() {
    
    this.showPasswordInput = this.route.url=='/admin' || this.adminSuccess ? false : true;
    if(this.route.url=='/billing'){
      this.showPasswordInput =false
    }
    
    
   
    if(this.adminSuccess){
      this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
        // Then navigate back to the admin route
        this.router.navigate(['/admin']);
      });
  }
}


  checkPassword() {
    
    if (this.password === this.correctPassword) {
      this.router.navigate(['/admin']).then(() => {
        localStorage.setItem("admin","true");
        this.adminSuccess = true;
        this.showPasswordInput = false;
        console.log('Navigation successful');
      }).catch(err => {
        console.error('Navigation failed', err);
      });
      
    } else {
      alert('Incorrect password');
    }
  }
  
  redirectTo(goto:any){
    this.router.navigate([goto]).then(()=>{
      this.responsiveMenuVisible = !this.responsiveMenuVisible;
      console.log('Navigation successful');
      this.showPasswordInput=false;
    }).catch(err => {
      console.error('Navigation failed', err);
    });
  }

  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }

  }

  downloadCV(){
    this.cvName = "PriceList.pdf"
    let url = window.location.href;

    // Open a new window with the CV
    window.open(url + "/../assets/cv/" + this.cvName, "_blank");

  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
    getScrollPosition(event) {
        this.pageYPosition=window.pageYOffset
    }

    changeLanguage(language: string) {
      this.languageFormControl.setValue(language);
    }

    toggleMenu() {
      this.responsiveMenuVisible = !this.responsiveMenuVisible;
    }
}

