<section class="section more-proyects">
  <div class="container">
      <div class="section-box">
          <div class="">
              <h3 class="section-title">
                  {{"OtherProjects.Title" | translate}}
              </h3>
          </div>
          <div class="mt-5">
              <div class="row p-0">

                  <div *ngFor='let project of "OtherProjects.Projects" | translate; let i = index'
                    
                   class="col-12 col-md-6 col-lg-6 col-xl-4 proyect-col" data-aos="fade-up" data-aos-duration="1000">
                      <div class="more-proyect-box">
                          <div class="row w-100 text-left m-0 p-0">
                              
                              <h5 class="other-proyect-title mt-4">{{project.Title}} | Age : {{project.Age}}</h5>
                              <p class="other-proyect-description">
                                  {{project.Description}}
                              </p>
                          </div>
                          <div   class="li">
                               
                                   <p><i class="fas fa-users"></i>&#160;{{project.Supply}}</p>
                                   <p><i class="fas fa-map-marker-alt"></i>&#160;{{project.Location}}</p>
                           
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          <!-- <div class="w-100 text-center mt-5 pt-5">
              <a [routerLink]="'/proyectos'" class='main-btn'>Ver todo</a>
          </div> -->
      </div>
  </div>
</section>
