<div class="container" id="image">
    <div class="row">
      <div class="col-12 justify-content-center" style="padding-top: 5rem;">
          <div id="landscape-carousel" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                  <div class="carousel-item active">
                      <img src="assets/offer.png" class="d-block w-100" alt="Image 1">
                  </div>
                  <!-- <div class="carousel-item">
                      <img src="assets/offer2.png" class="d-block w-100" alt="Image 2">
                  </div>                   -->
              </div>              
          </div>
      </div>
    </div>
  </div>
