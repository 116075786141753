<section class="section contact" id="store">
  <div class="floating-icon" *ngIf="!ShowFloating" (click)="openPreview(content)">
    <div class="icon-link" >
      <i class="fas fa-shopping-cart"></i>
    </div>
  </div>    
  <div class="container">
  

    <ng-template #content let-modal>
        <div class="modal-header">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="success" class="alert alert-success">
          <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}. Please check your mail for customer copy!
      </div>
      <div *ngIf="error"  class="alert alert-danger">
        <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-72005 82944"
    </div>
    <div class="modal-body" id="invoice-container">
      <div class="container-fluid  mt-4" style="padding: 2px !important;">
        <div class="row">
          <div class="col-3">
            <h6>Enquiry No - #{{randomNum}}</h6>
          </div>
          <div class="col-6">
            <div class="row text-center">  
              <!-- <div class="col-md-auto" style="padding: 0px;">
                <div class="logo">
                  <img src='assets/viswaiCraclogo.png' width='85px' height="100px" />
                </div>
              </div>           -->
              <div class="col-md-auto">
                <div><h5>Cherish Crackers</h5></div>
                <div>
                  2/3261, Opposite to karuman Transport, Viswanatham, Sivakasi-626189
                </div>
         
                <!-- <div>GSTIN</div> -->
              
              </div> 
           
            </div>
          </div>
          <div class="col-3">
            Total: {{ getTotalPrice() | number:'1.2-2' :'en-US' }}
            <br>
            Discount : {{ allTemplate.discount }} %
          </div>
        </div>
        <div class="row phoneEmail">
          <div class="col-3"><b>Phone </b>: 72005 82944
             <br>
             <b>Date </b>: {{ todayDate | date:'yyyy-MM-dd' }}
        </div>
          <div class="col-5"></div>
          <div class="col-4"> <b>E-mail</b> : cherishcrackers@gmail.com</div>

        </div>
        <hr>
        <div class="CD">
          <div class="cd">Customer Details :</div>
          <div>Name: {{customerName}}</div>
          <div>phone Number: {{customerPhoneNumber}} </div>
          <div>address: {{customerAddress}} </div>
        </div>
        <br>
        

          <table class="table table-bordered invoice storeEmail" >
           
            <thead>
              <tr>
                <th>S.no</th>
                <th>Code</th>
                <!-- <th>Category</th> -->
                <th>{{isDesktopDevice ? 'Product Name' : 'Product'}}</th>
                <th>Quantity</th>
                <th>Actual Rate</th>
                <th>{{isDesktopDevice ? 'Discount Price' : 'Discount'}}</th>
               
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let category of filteredTemplate; let c = index">

              <tr *ngFor="let item of category.fields; let i = index">
           
                <td>{{ calculateSerialNumber(c, i) }}</td>                     <!-- <td>
                  <img *ngIf="item.image" class="avImg cImage" [src]="item.image" alt="Image" (click)="openFullScreen(item.image)">
                  <span *ngIf="!item.image">No Image</span> 
                </td> -->
                <!-- <td>{{ item.category }}</td> -->
                <td >{{ item.id }}</td>    
                <td>{{ item.product }}</td>
                <td class="hideInMail" style="width: 10%;">
                  <input
                  id="removetrash"
                  style="width: 100%;"
                  [(ngModel)]="item.count" 
                  (ngModelChange)="updateCountBill(category,item, $event)" 
                    type="number" 
                    name="count" 
                    min="1" 
                    step="1" 
                  value="{{ item.count }}"
                    #countInput
                  />
                </td>
                <td class="showInMail">{{item.count }}</td>
                <td> {{ item.price | number:'1.2-2' :'en-US'  }}</td>
                <td> {{ item.discount | number:'1.2-2' :'en-US'  }}</td>
                
                <td> {{ (item.discount * item.count) | number:'1.2-2' :'en-US'  }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
         
         
        </div>
  </div>
  <form #customerForm="ngForm">
    <div class="modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" required [(ngModel)]="customerName" #nameField="ngModel">
            <div 
            *ngIf="nameField.touched && nameField.invalid">
            <small *ngIf="nameField.errors?.required">Name is required</small>
          </div>
          </div>
          <div class="col-md-3">
            <label for="phoneNumber">Phone Number:</label>
            <input type="number" id="phoneNumber"  name="number" [(ngModel)]="customerPhoneNumber" #number="ngModel" inputmode="numeric" pattern="^[0-9]*$" maxlength="10" required >
            <div *ngIf="number.invalid && number.touched">
              <small *ngIf="number.errors?.required">Number is required.</small>
              <small *ngIf="number.errors?.pattern">Only numbers are allowed.</small>
              <small *ngIf="number.errors?.minlength">Number must be 10 digits long.</small>
              <small *ngIf="number.errors?.maxlength">Number cannot exceed 10 digits.</small>
            </div>
          </div>
          <div class="col-md-3">
            <label for="address">Address:</label>
            <input id="address" name="address"  #address="ngModel" required [(ngModel)]="customerAddress">
            <div *ngIf="address.invalid && address.touched">
              <small *ngIf="address.errors?.required">Address is required.</small>
            </div>
          </div>
          <div *ngIf="success" class="alert alert-success">
            <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
        </div>
        <div *ngIf="error"  class="alert alert-danger">
          <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-72005 82944"
      </div>
      <div *ngIf="downloadSuccess" class="alert alert-success">
        <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
    </div>
        </div>

      </div>
      <div *ngIf="showErrorMsg">
        please enter the customer details
      </div>
      <button type="button" class="btn-list"  [ngClass]="{'btn-primary': isLoading, 'btn-outline-dark': !isLoading , 'btn-light': customerForm.invalid}"  [disabled]="isLoading || customerForm.invalid" (click)="onDownloadInvoice(content)">
        <span  *ngIf="isLoading">Loading...</span>
        <span *ngIf="!isLoading">Submit</span>
      </button>
      <!-- <button type="button" class="btn btn-outline-dark" (click)="sendEmail()">Sent Mail</button> -->
    </div>
  </form>
    </ng-template>
    
    
    
    <hr />
    <div class="section-box">
      <div class="storeArea">
        <div class="tabbed-container">
            
           <div class="row">
            <div class="col-6 right">
                <h2 class="mhead" style="color:#d324a0;">Shop the Best Deals Right Here!</h2>
            </div>
            
            <div class="col-3 preview"> <button type="button"
                class="btn btn-outline-primary" (click)="openPreview(content)">Preview </button></div>
            <div class="col-3 preview"> <button  type="button"
                class="btn btn-outline-primary" (click)="clearBtn()">Clear </button>
            </div>
            
          
           </div>
           <ul class="list-unstyled" style="margin-top: 1rem;">
            <li>How it Works!
              <ul>
                <li>&#9642; Please Mention your Full name, contact number, Address where you want the crackers to be delivered</li>
                <li>&#9642; Once you submitted your enquiry our executive will call you within 12 hours</li>
                <li>&#9642; Delivery cost varies depends on the order. For orders more than Rs.3000, You will get an existing gift from us.</li>
                <li>&#9642; Your orders will be verified with them and payment details will be shared</li>
                <li>&#9642; All of the orders will delivered via reputated parcel services near by your location.</li>
                <li>&#9642; As per Supreme Court Law, crackers should not be sold online. So this is the procedure we have to follow.</li>                
              </ul>
            </li>    
          </ul>
           
          <div class="nav nav-tabs">
            <input type="text" class="Search" placeholder="Search..." [(ngModel)]="searchQuery" (ngModelChange)="filterTemplate()" />
  

          </div>

          <div class="tab-content">
           
            <div *ngIf="activeTab === 'tab1'" class="">
              <ul>
                <li class="mainName" *ngFor="let key of filteredSearchTemplate; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleGroundCollapse(i)"
                    [attr.aria-expanded]="!isGroundCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                    <img class="avImg" src="../../../../assets/images/me.svg" alt="Image">
                    {{ key.name }}
                    <i style="padding: 18px;" class="fa fa-chevron-down"></i>
                  </button>
              
                  <div [ngbCollapse]="isGroundCollapsed[i]" class="card">
                    <div class="card-body">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            
                            <th scope="col">Code</th> 
                            <th scope="col">Image</th>
                            <th scope="col">Product</th>
                            <th scope="col">{{isDesktopDevice ? 'Actual price' : 'A.price'}}</th>
                            <th scope="col discountPrice">{{isDesktopDevice ? 'Discount Price' : 'Discount'}}</th>                            
                            <th scope="col">Quantity</th>
                            <th scope="col">Availability</th>
                          </tr>
                        </thead>
                        <ng-container *ngFor="let subCategory of key.fields; let j = index">
                          <tbody>
                            <tr>
                              <!-- <td>{{ getSerial(i,j,filteredSearchTemplate,key.fields)}}</td>  -->
                           
                              <td>{{ subCategory.id }}</td>
                           
                              <td>
                                <img *ngIf="subCategory.image" class="avImg cImage" [src]="subCategory.image" alt="Image" (click)="openFullScreen(subCategory.image,subCategory.product)">
                                <span *ngIf="!subCategory.image">No Image</span> 
                              </td>
                              <td>{{ subCategory.product }}</td>
                              <td class="actualPrice"> {{ subCategory.price }}</td>
                              <td style="width: 15%;" class="discountPrice"> {{ subCategory.discount | number:'1.2-2':'en-US' }}</td>

                              <td style="width: 12%;" >
                                <ng-container *ngIf="subCategory.availability; else unavailable">
                                <input type="number" class="form-control" [(ngModel)]="subCategory.count" (ngModelChange)="updateCount(i, j, $event)" min="0">
                                </ng-container>
                                <ng-template #unavailable>
                                  <i class="fa fa-minus"></i>
                                </ng-template>
                              </td>
                              <td style="float:left">
                                <i style="color: green" *ngIf="subCategory.availability" class="fa fa-check"></i>
                                <i *ngIf="!subCategory.availability"  class="fa fa-times"></i>
                              </td>
                            </tr>
                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>
             
             
          
            </div>
           
          
          </div>
        </div>
       
      </div>
    </div>
  </div>
</section>
