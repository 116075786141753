// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDfZCOU_j0RYOOC_SXyPD6nAw9F4oHYTvQ",
  authDomain: "cherishcracker.firebaseapp.com",
  projectId: "cherishcracker",
  storageBucket: "cherishcracker.appspot.com",
  messagingSenderId: "351590776734",
  appId: "1:351590776734:web:d4c2cf4f1dd4bf97a3e08f",
  measurementId: "G-W38FGT58YM"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
