

import { Component } from "@angular/core";
import { ModalDismissReasons, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { IpserviceService } from "src/app/services/ipservice/ipservice.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TemplateManagerService } from "src/app/services/fire/template.service";
import { FullScreenImageComponent } from "../full-screen-image/full-screen-image.component";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {


  
  collapsed: true;
  deviceInfo = null;
  ShowFloating: boolean = false;
  isGroundCollapsed: boolean[] = [];
  isSkyCollapsed: boolean[] = [];
  isFancyCollapsed: boolean[] = [];
  activeTab: string = "tab1";
  groundCrackersKeys: string[];
  fireworksData: any;
  showErrorMsg: boolean = false;
  GroundCrackers: any;
  randomNum: any;
  skyCrackers: any;
  closeResult = "";
  fancyCrackers: any;
  success: boolean = false;
  error: boolean = false;
  isDesktopDevice:any;
  skyCrackersKeys: string[];
  fancyCrackersKeys: string[];
  customerName: string = "";
  customerPhoneNumber: string = "";
  customerAddress: string = "";
  customerEmail: string = "";
  isSendDisabled: boolean = true;
  downloadSuccess: boolean = false;
  isLoading: boolean = false;



  primaryTemplate: any;
  filteredTemplate: any;
  allTemplate: any;
  filteredSearchTemplate: any;
  searchQuery: string = '';
  templates$: Observable<any[]>;
  dataValue: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  templatesCollection: AngularFirestoreCollection<any>;
  selectedTemplate: any;
  selectedTemplateId: string;
  filterTotalPrice: number;
  modalRef: NgbModalRef;
  todayDate: Date;

  constructor(
    private ipserviceService: IpserviceService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private templateManagerService: TemplateManagerService,
    private afs: AngularFirestore
  ) {
    this.templatesCollection = this.afs.collection('templates');
    this.templates$ = this.dataValue.asObservable();
    this.todayDate = new Date(); 
  }

  ngOnInit() {
    
    window.scrollTo(0, 0);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.templates$ = this.dataValue.asObservable();
    this.getPrimaryTemplate();
    this.getCollection();

  }

  
 
  getCollection() {
    this.templatesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const id = a.payload.doc.id;
          const data = { id, ...a.payload.doc.data() };
          return data;
        });
      }),
      catchError(error => {
        return throwError(error);
      })
    ).subscribe(
      (data) => {
        this.dataValue.next(data);
        if (data.length > 0 && !this.selectedTemplate) {
          // Automatically select the first template if none is selected
          this.selectedTemplateId = data[0].id;
          this.selectedTemplate = data[0];
        }
      },
      (error) => {
        console.error('Error fetching collection:', error);
      }
    );
  }



  onTemplateChange(templateId: string): void {
    this.selectedTemplateId = templateId;
    this.selectedTemplate = this.getTemplateById(templateId);
  }

  editTemplate(templateId: string): void {
    const template = this.getTemplateById(templateId);
    if (template) {
      
      // Your logic to edit the template
      console.log('Editing template:', template);
      this.getTemplateID();
    }
  }

  selectItem(templateId: string): void {
    this.templates$.subscribe(templates => {
      const template = templates.find(t => t.id === templateId);
      if (template) {
        template.isPrimary = true;
        console.log('Template selected:', template);
      }
    });
  }

  getTemplateById(templateId: string): any {
    let selectedTemplate;
    this.templates$.subscribe(templates => {
      selectedTemplate = templates.find(template => template.id === templateId);
    });
    return selectedTemplate;
  }

  

  getSelectedTemplate(): any {
    let selectedTemplate;
    this.templates$.subscribe(templates => {
      selectedTemplate = templates.find(template => template.id === this.selectedTemplate);
    });
    return selectedTemplate;
  }

  openFullScreen(imageUrl: string): void {
    
    if(imageUrl != '' && imageUrl != null){
       this.modalRef = this.modalService.open(FullScreenImageComponent, { size: 'lg' });
      this.modalRef.componentInstance.imageUrl = imageUrl;
    }
  }

  getTemplateID(){
    this.templateManagerService.getTemplateById(this.selectedTemplateId).subscribe(
      template => {
        
        this.allTemplate = template;
        this.primaryTemplate = template.categories.map(category => ({
          ...category,
          fields: category.fields.map(field => ({
            ...field,
            count: 0,
            category: category.name
          }))
        }));
        this.filteredSearchTemplate = this.primaryTemplate
        console.log('Primary Template:', this.primaryTemplate);
      },
      error => {
        console.error('Error fetching primary template:', error);
      }
    );
  }


  getPrimaryTemplate() {
    this.templateManagerService.getPrimaryTemplateFromFB().subscribe(
      template => {
        
        this.allTemplate = template;
        this.primaryTemplate = template.categories.map(category => ({
          ...category,
          fields: category.fields.map(field => ({
            ...field,
            count: 0,
            category: category.name
          }))
        }));
        this.filteredSearchTemplate = this.primaryTemplate
        console.log('Primary Template:', this.primaryTemplate);
      },
      error => {
        console.error('Error fetching primary template:', error);
      }
    );
  }

   // Method to filter the template based on search query
   filterTemplate() {
    if (this.searchQuery.trim() === '') {
      this.filteredSearchTemplate = [...this.primaryTemplate]; // If search query is empty, show all items
    } else {
      this.filteredSearchTemplate = this.primaryTemplate.map(category => {
        return {
          ...category,
          fields: category.fields.filter(item => 
            category.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.product.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.id.toString().includes(this.searchQuery) 
          )
        };
      }).filter(category => category.fields.length > 0); // Remove empty categories
    }
  }

  updateCount(cindex:number, findex: number, count: number) {
    count = count ?? 0;
    this.primaryTemplate[cindex].fields[findex].count = count;
  }


  updateCountBill(cindex:number, findex: number, count: number) {
    count = count ?? 0;
    this.filteredTemplate[cindex].fields[findex].count = count;
  }

 

  showTab(tabName: string) {
    this.activeTab = tabName;
  }

  getTotalPrice(): number {
    let totalPrice = 0;
    for (let category of this.filteredTemplate) {
      for (let item of category.fields) {
        totalPrice += item.discount * item.count;
      }
    }
    this.filterTotalPrice = totalPrice;
    return totalPrice;
  }
  calculateSerialNumber(c: number, i: number): number {
    let serialNumber = 0;
    for (let j = 0; j < c; j++) {
      serialNumber += this.filteredTemplate[j].fields.length;
    }
    return serialNumber + i + 1;
  }


  getinvoiceElementID() {
    const element = document.getElementById("invoice-container") as HTMLElement;
    element.style.border = "none";

    return element;
  }

  
  onDownloadInvoice(content:any): void{
    this.isLoading = true; // Start loading

    this.downloadInvoiceAsPDF(content).finally(() => {
      this.isLoading = false; // Stop loading once done
    });
  }
  downloadInvoiceAsPDF(content: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const data: any = this.getinvoiceElementID();
      const imgWidth = 595; // A4 page width in pixels
      const pageHeight = 842; // A4 page height in pixels
      const padding = 20; // White space at the bottom of each page in pixels

      html2canvas(data).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg', 0.6);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdf = new jspdf.jsPDF({
          orientation: 'portrait', // Use 'landscape' for landscape orientation
          unit: 'px', // Set units to pixels
          format: [imgWidth, pageHeight] // Set the dimensions in pixels (A4: 595 x 842)
        });

        let y = 0;

        while (y + imgHeight > pageHeight - padding) {
          pdf.addImage(imgData, 'JPEG', 0, y, imgWidth, imgHeight);
          y -= pageHeight; // Move to the next page
          pdf.addPage();
        }
        pdf.addImage(imgData, 'JPEG', 0, y, imgWidth, imgHeight); // Add the remaining content

        const currentDateTime = moment().format('DDMMYYYY_HHmmss'); // Improved format for filename
        pdf.save(`invoice_${currentDateTime}.pdf`);

        this.saveDataFire(content);

        resolve(); // Resolve the promise after completing the PDF generation
      }).catch(error => {
        console.error('Error generating PDF:', error);
        reject(error); // Reject the promise in case of an error
      });
    });
  }


  saveDataFire(content:any){
    

    let saveJson = {
      data: this.filteredTemplate,
      customerName:this.customerName,
      customerPhoneNumber:this.customerPhoneNumber,
      customerAddress : this.customerAddress,
      customerEmail: this.customerEmail,
      filterTotalPrice:this.filterTotalPrice,
      
    }

    this.templateManagerService.saveBillingData(saveJson)
    .then(() => {
    this.modalRef
    console.log('Data saved successfully');
    this.modalService.dismissAll();
    })
    .catch((error) => {
      console.error('Error saving data: ', error);
    });


  }

  checkFields() {
    // Check if all three fields are filled
    const result =
      this.customerName != "" &&
      this.customerPhoneNumber != "" &&
      this.customerAddress != "";
      this.customerEmail != "";

    return result;
  }

  async getInvoiceAsBase64(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data: any = this.getinvoiceElementID();
    const imgWidth = 595; // A4 page width in pixels
    const pageHeight = 842; // A4 page height in pixels
    const padding = 20; // White space at the bottom of each page in pixels
  
    html2canvas(data).then(canvas => {
      const imgWidth = 595; // A4 width in pixels
      const pageHeight = 842; // A4 height in pixels
      const padding = 5; // Padding to avoid content near the edge
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate image height while maintaining aspect ratio
      const content = canvas.toDataURL('image/jpeg', 1.0); // Use higher quality (1.0) for better output
    
      let pdf = new jspdf.jsPDF({
        orientation: 'portrait', // Portrait mode
        unit: 'px', // Units in pixels
        format: [imgWidth, pageHeight] // A4 dimensions
      });
    
  
     
      let y = 0; // Initial Y-coordinate
      let position = 0; 
  
      while (position < canvas.height) {
        const pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = pageHeight * (canvas.width / imgWidth); // Set height proportionally to imgWidth
    
        const ctx = pageCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, position, canvas.width, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height);
    
        const pageImg = pageCanvas.toDataURL('image/jpeg', 1.0);
        pdf.addImage(pageImg, 'JPEG', 0, 0, imgWidth, pageHeight);
    
        position += pageCanvas.height; // Move to the next section of the canvas
    
        if (position < canvas.height) {
          pdf.addPage(); // Add a new page if there's more content
        }
      }
    
      const pdfData = pdf.output("datauristring"); // Generate the PDF as a data URI string
      resolve(pdfData); // Resolve with the generated PDF data URI
    });
    });
  }
  

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Example usage
  getRandomNumber() {
    this.randomNum = this.generateRandomNumber(1, 10000); // Generate a random number between 1 and 100
    console.log("Random Number:", this.randomNum);
  }






 


  trash(id) {
 
    const existingIndex = this.filteredTemplate.findIndex((item) => item.id === id);

    if (existingIndex !== -1) {
      this.filteredTemplate.splice(existingIndex, 1);
    }
  }

  filterPrimaryTemplate() {
    let arrayTemp = [];
  
    // Iterate over each category in primaryTemplate
    this.primaryTemplate.forEach(category => {
      // Filter fields within each category where count is not equal to 0
      let filteredFields = category.fields.filter(field => field.count !== 0);
  
      // If filtered fields exist (i.e., not empty), add category with filtered fields to arrayTemp
      if (filteredFields.length > 0) {
        arrayTemp.push({
          ...category,
          fields: filteredFields // Replace original fields with filtered fields
        });
      }
    });
  
    this.filteredTemplate = arrayTemp;
  }
  


  openPreview(content:any) {
    this.ShowFloating = true;
    this.getRandomNumber();
    this.filterPrimaryTemplate();
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        windowClass: "modal-xl",
      })
      .result.then(
        (result) => {
          this.ShowFloating = false;
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.ShowFloating = false;
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  toggleGroundCollapse(index: number) {
    this.isGroundCollapsed[index] = !this.isGroundCollapsed[index];
  }
  toggleSkyCollapse(index: number) {
    this.isSkyCollapsed[index] = !this.isSkyCollapsed[index];
  }
  toggleFancyCollapse(index: number) {
    this.isFancyCollapsed[index] = !this.isFancyCollapsed[index];
  }

  getJson() {
    this.GroundCrackers = this.fireworksData.GroundCrackers;
    this.groundCrackersKeys = Object.keys(this.GroundCrackers);

    // Initialize isCollapsed array with false values
    // this.isGroundCollapsed = new Array(this.groundCrackersKeys.length).fill(
    //   true
    // );

    this.skyCrackers = this.fireworksData.SkyCrackers;
    this.skyCrackersKeys = Object.keys(this.skyCrackers);

    // Initialize isCollapsed array with false values
    // this.isSkyCollapsed = new Array(this.skyCrackersKeys.length).fill(true);

    this.fancyCrackers = this.fireworksData.FancyCrackers;
    this.fancyCrackersKeys = Object.keys(this.fancyCrackers);

    // Initialize isCollapsed array with false values
    // this.isFancyCollapsed = new Array(this.fancyCrackersKeys.length).fill(true);
  }
  getMain(value) {
    let name = "";
    if (value == 0) {
      name = "SkyCrackers";
    } else if (value == 1) {
      name = "FancyCrackers";
    } else if (value == 2) {
      name = "GroundCrackers";
    } else if (value == 3) {
      name = "Gift Box";
    }
    return name;
  }



  clearBtn() {
    
   
    this.ngOnInit()
  }
  getUniqueIdentifier(key, subCategory) {
    // You can concatenate key and subCategory values to create a unique identifier
    // For example:
    return key + "_" + subCategory.key;
  }
}

