<section class="section banner">
    <img src="../../../../assets/img/sale.svg" alt="SVG Image" />

    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>Welcome to Cherish !</h1>
                </div>
                <div class='banner-title'>
                    <h2 class="mobileh2">
                        Diwali is happening everywhere, so let's spread love and kindness by sending warm wishes to everyone</h2>
                </div>
                <div class='banner-description'>
                    <p  class="mt-4">For over a decade, we've been in the cracker business, making customers smile with every burst of color and sparkle. Our commitment to safety and top-notch quality has delighted thousands of happy customers.</p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a  routerLink="/store" class="main-btn">
                   Shop Now
                </a>
            </div>
        </div>
    </div>
</section>
