
<div *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store')"  class="contact-icons">
  <a href="tel:+917200582944" class="icon-call">
    <img  width="68px" src="assets\images\callus.png" class="contactstic" alt="sticker">
  </a>
</div>
<div *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store')"  class="contact-icons">
  <a href="https://wa.me/7200582944" class="whatsapstic" target="_blank">
    <img  width="80px" src="assets\images\watsapp-removebg-preview.png" class="whatsapstic" alt="sticker">
  </a>
</div>
<div class="quick" *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store') " (click)="gotoStore()">
  <img height="240px"  src="assets/images/quickcopy1.png" class="quickstic" alt="sticker">
 </div>
<div class="container footer" id="footerArea">
  <div class="col-lg-12 col-12 colright">
  <div class="row justify-content-center mobilelogo">
      <!-- First column -->
      <div class="col-lg-1 d-none d-lg-block"></div>
      <div class="col-lg-3 col-12 mb-1  " >
          <img class="unitrix" src="assets/images/me/LOGO.png" alt="Logo" style="width: 50%">
          <div style="font-size: 16px;">Join us in celebrating the simple joy of crunching, and let our crackers elevate<br> your every occasion</div>
      </div>

      
      
              
              <div class="col-lg-2 col-6 mb-2">
                  <div class="head"><p>Quick Links</p></div>
                  <div><p>Facebook</p></div>
                  <div><p>Instagram</p></div>  
                  <div class="head"><p>Email:</p></div>        
                  <div (click)="mailMe()" ><a  href="mailto:cherishcrackers@gmail.com">cherishcrackers&#64;gmail.com</a></div>
              </div>
              <div class="col-lg-3 col-6 mb-2">
                  <div class="head"><p>Reach Us Here</p></div>
                  <div><p>3/766, B-1, Opposite Karuman Transport,
                      Vetrilaiyurani Road, Sivakasi,Tamil Nadu - 626 189</p></div>
              </div>
              <div class="col-lg-2 col-12 mb-12 mobile-chat">
                  <div class="head-chat" style="color:#d324a0 ;"><p>Chat With Us : </p> 
                    <div><a href="tel:7200582944">7200-582-944 ,</a> </div>
                    <div><a href="tel:9047887717">9047 887717</a></div>
                  </div>
                  
                 
                </div>
                <div class="col-lg-1 d-none d-lg-block"></div>
             
             
          </div>
      </div>
  </div>
  <div class="company text-center mt-4">
    <div class="com">
        Copyright © 2005-2023 Cherish Crackers. All rights reserved. | 
        <a href="#" class="text-decoration-none">Privacy Policy</a> | 
        <a [routerLink]="'/terms'" class="terms text-decoration-none">Terms of Service</a>
    </div>
  </div>
  








