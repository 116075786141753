<nav
  [@animateMenu]
  class="navbar main-navbar on-top"
  [class.nav-shadow]="this.pageYPosition > 0"
>
  <div class="container">
    <a   (click)="redirectTo('/home')" class="navbar-brand brandname" style="padding-left: 2rem ;color: #fff;;">
      <img src='assets/images/me/LOGO.png' width='85px' height="70px" /> 
    </a>
    <ul ngbNav #nav="ngbNav" class="menu-ul">
      <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")' style="line-height: 32px;">
        <a (click)="redirectTo('/banner')">
          <span class="nav-number"></span>
          <span class="underline nav-text">HOME</span>
        </a>
        <ng-template ngbNavContent>Home content</ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink (click)='scroll("about")'>
          <span class="nav-number"></span>
          <span class="underline nav-text">ABOUT</span>
        </a>
        <ng-template ngbNavContent>About content</ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink (click)="redirectTo('/store')">
          <span class="nav-number"> </span>
          <span class="underline nav-text">
            SHOP </span
          ></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>


      <li ngbNavItem>
        <a ngbNavLink (click)='scroll("proyects")'>
          <span class="nav-number"></span>
          <span class="underline nav-text">DEMO</span>
        </a>
        <ng-template ngbNavContent>Second content</ng-template>
      </li>

      
      <!-- <li ngbNavItem >
                <a ngbNavLink (click)='scroll("about")'><span class='nav-number'> </span> <span class="underline nav-text"> {{"Header.Item5" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
     


      <li>
        <a ngbNavLink ngbNavItem (click)="togglePasswordInput()"><span class='nav-number'> </span> <span class="underline nav-text"> ADMIN </span></a>
        <ng-template ngbNavContent>Second content</ng-template>
        <div *ngIf="showPasswordInput" class="adminAreaI">
            <input type="password" [(ngModel)]="password" placeholder="Enter password" />
            <button class="btn btn-primary " style="margin-left: 4px; padding: 3px 14px !important;background-color: #d324a0 !important; transition: background 0.3s ease; box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);border: none;" (click)="checkPassword()">Submit</button>
          </div>
       
    </li>

    <li ngbNavItem  *ngIf="adminSuccess">
      <a ngbNavLink (click)="redirectTo('/billing')"
        ><span class="nav-number"> </span>
        <span class="underline nav-text">
          BILLING </span
        ></a
      >
      <ng-template ngbNavContent>Second content</ng-template>
    </li>
    <li ngbNavItem>
      <a (click)="downloadCV()" class="btn btn-outline-primary btn-list">
       Download
      </a>
      <ng-template ngbNavContent>price content</ng-template>
    </li>

    

      <!-- <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                <a ngbNavLink (click)='scroll("proyects")'><span class='nav-number'> </span> <span class="underline nav-text"> {{"Header.Item3" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
      <!-- <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                <a ngbNavLink (click)='scroll("contact")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item4" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
      <!-- <li ngbNavItem>
                <a (click)="downloadCV()" class="btn btn-outline-primary cv-btn" ngbNavLink>
                  {{"Header.cvBtn" | translate}}
                </a>
            </li> -->
    </ul>    
<div class="menu-wrapper">
      <div
      (click)='responsiveMenuVisible = !responsiveMenuVisible' 
      class="hamburger-menu">
    </div>
    </div>
    <div 
      class="menu-responsive"
      [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }"
    >
    <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
        <nav>
          <ol>
            <li (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
              <a (click)='scroll("about")'>
                <span style="color: #d324a0;">Home</span>
              </a>
            </li>

            <li
            (click)="redirectTo('/store')"
            >
              <a  (click)="scroll('image')">
                <span style="color: #d324a0;">Shop</span> 
              </a>
            </li>

            <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
              <a (click)='scroll("proyects")'>
                  Demo
              </a>
          </li>

          <li (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
            <a (click)='scroll("proyects")'>
                Contact Us
            </a>
          </li>

          
            <li>
              <a (click)="downloadCV()" class=" btn btn-outline-primary btn-list mobileprice">
               Download
              </a>
            </li>

            
        
                    
            <!-- <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_jobs',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>03. </span> {{ "Header.Item3" | translate }}
              </a>
            </li>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_contact',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>04. </span> {{ "Header.Item4" | translate }}
              </a>
            </li>
            <li>
              <a (click)="downloadCV()" class="btn btn-outline-primary cv-btn">
                {{ "Header.cvBtn" | translate }}
              </a>
            </li> -->

            <!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
          </ol>
        </nav>
      </aside>
    </div>
  </div>
</nav>
