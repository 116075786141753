<section class="section about" id='about'>
  <div class="container">
      <div class="section-box">
          <div class="about-title" data-aos="fade-up">
              <h3 class="section-title">
                  {{"AboutMe.Title" | translate}}
              </h3>
          </div>
          <div class="row">
              <div class="col-12 col-md-6 mb-4 mb-md-0">
                  <div class="about-description">
                      <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                      </p>
                      <ul class="skills-list" data-aos="fade-up">
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Keep a safe distance </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Adult supervision </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Protective gear </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Flat, stable surface </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ One at a time </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Stay sober </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Respect local laws </li>
                          <li class="skill-element" data-aos="fade-up" data-aos-duration="1000">▹ Pets </li>                            
                      </ul>
                  </div>
              </div>
              <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                  <div class="about-img-container">
                      <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="300" height="300" src="assets/images/me/LOGO.png" alt="Git User">
                      <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="200" height="200" src="assets/images/offer.png" alt="Git User">
                  </div>                    
              </div>

          </div>
      </div>
  </div>
</section>
