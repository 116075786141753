import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from "src/app/services/language/language.service"
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cherish-crackers';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService,
    private router: Router
   
  ) {
  }

  ngOnInit(): void {

    this.languageService.initLanguage()

    

    this.router.navigate(['/home']).then(() => {
      console.log('Navigation successful');
    }).catch(err => {
      console.error('Navigation failed', err);
    });

    this.titleService.setTitle("cherish");
    this.metaService.addTags([
      { name: 'keywords', content: 'cherish , cherish crackers, sivakasi cherish' },
      { name: 'description', content: 'cherish , cherish crackers, sivakasi cherish' },
    ]);
    
    AOS.init();


  }

  showbanner(): boolean {
    return this.router.url.startsWith('/store');
  }
}
