<section class="section proyects" id="proyects">
    <div class="container">
      <div class="section-box">
        <div data-aos="fade-up" data-aos-duration="1000">
          <h3 class="section-title">
            {{ "FeatureProjects.Title" | translate }}
          </h3>
        </div>
  
        <div
          *ngFor="
            let project of 'FeatureProjects.Projects' | translate;
            let i = index
          "
          class="d-flex proyect-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <video
            controls
            src="/assets/video/vid-one.mp4"
            class="hover-video"
            *ngIf="i % 2 == 0"
          ></video>
  
          <div
            [class.proyect-info-right]="i % 2 == 0"
            [class.proyect-info-left]="i % 2 != 0"
            class="h-100 proyect-content-box hover-div"
            style="background-image: url('{{ project.img }}');"
          >
            <div class="proyect-box">
              <h5
                [class.text-right]="i % 2 == 0"
                [class.text-left]="i % 2 != 0"
                class="proyect-name"
              >
                {{ project.Title }}
              </h5>
  
              <div class="proyect-description-box" (click)="debug()">
                <p class="mb-0" [innerHTML]="project.Description"></p>
              </div>
              <ul
                [class.justify-content-end]="i % 2 == 0"
                [class.justify-content-start]="i % 2 != 0"
                class="proyect-skills"
              >
                <li *ngFor="let technology of project.Technologies" class="ml-3">
                  <span class="underline technology">{{ technology }}</span>
                </li>
              </ul>
            </div>
          </div>
  
          <video
            *ngIf="i % 2 != 0"
            src="/assets/video/vid-one.mp4"
            class="hover-video"
            controls
          ></video>
        </div>
        <div></div>
      </div>
    </div>
  </section>
  