import { Component, OnInit } from "@angular/core";
import { AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { IpserviceService } from "src/app/services/ipservice/ipservice.service";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {

  submittedData: any;
  isCollapsed: boolean[] = [];
  fullscreenImage: string | null = null;
  showFullscreen: boolean = false;
  templatesCollection: AngularFirestoreCollection<any>;
  selectedTemplateId: any;

  constructor(private ipservice: IpserviceService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.submittedData = this.ipservice.submitted;
    this.selectedTemplateId = this.ipservice.selectedID;
  }
  
  
  openImage(imageUrl: string) {
    this.fullscreenImage = imageUrl;
    this.showFullscreen = true;
  }

  
  onSave() {
    if (this.selectedTemplateId) {
      this.templatesCollection.doc(this.selectedTemplateId).update(this.submittedData);
    } else {
      this.templatesCollection.add(this.submittedData);
    }
    //royter admin
    this.submittedData = [];
    alert('Data uploaded successfully');
  }
}
